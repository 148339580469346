<script>

import SimpleInfoTable from "../../../components/SimpleInfoTable/SimpleInfoTable.vue";
import {httpRequest} from "../../../api";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import ImpressionsTool from "@/components/DataTables/Tools/ImpressionsTool/index.vue";
import DifficultyTool from "@/components/DataTables/Tools/DifficultyTool/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import RankChartTool from "@/components/DataTables/Tools/RankChartTool/index.vue";
import ResultsTool from "@/components/DataTables/Tools/ResultsTool/index.vue";
import CompetitorsInTopTool from "@/components/DataTables/Tools/CompetitorsInTopTool/index.vue";
import TranslateOneTool from "../../../components/DataTables/Tools/TranslateOneTool/index.vue";
import KeywordTool from "@/components/DataTables/Tools/KeywordTool/index.vue";
import InAsoTool from "@/components/DataTables/Tools/InAsoTool/index.vue";
import KeywordToolNew from "../../../components/DataTables/Tools/KeywordToolNew.vue";
import SvgIcon from "../../../components/UI/SvgIcon/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import {arraySum, formatDate, getDefaultMinDate, getNDaysAgoDate, openGlobalModal} from "../../../utils/utils";
import CustomSelectNew from "../../../components/Forms/CustomSelectNew/index.vue";
import ModalComponent from "../../../components/Modals/ModalComponent.vue";
import AddFolderModal from "./AddFolderModal.vue";
import RefsStore from "../../../utils/RefsStore";
import KeywordsGroups from "../../../api/modules/keywords-groups";
import AddKeywordsToFolderModal from "./AddKeywordsToFolderModal.vue";
import ProgressIndicator from "../../../components/UI/ProgressIndicator/index.vue";
import keywordsTracking from "../../../api/modules/keywords-tracking";
import MetadataKeywordsTable from "../../CompetitorsFinderPage/Competitors/Metadata/MetadataKeywordsTable.vue";
import ExpandRankTableChart from "@/components/DataTables/Tools/ExpandRankTableChart/ExpandRankTableChart.vue";
import {nextTick} from "vue";
import LiveSearchGlobalModalAdapter from "../../../components/Modals/LiveSearchModal/LiveSearchGlobalModalAdapter.vue";
import SapChartGlobalModalAdapter from "../../../components/Modals/SapChartModal/SapChartGlobalModalAdapter.vue";
import AutosuggestsGlobalModalAdapter
  from "../../../components/Modals/AutosuggestsModal/AutosuggestsGlobalModalAdapter.vue";
import Tabs from "../../../components/DataTables/GroupsAssets/Tabs.vue";
import {
  compInTop10Filter,
  difficultyFilter,
  estDailyImprFilter,
  languageFilter,
  nonCompInTop10Filter,
  popularityFilter,
  rankFilter,
  rankTrendFilter,
  wordsCountFilter
} from "../Common/filtersConfigs";
import {groupsConfig} from "../Common/groupsConfigs";
import {allTranslated, checkedItems} from "../Common/otherCommonComputed";
import {copyKeywords, translateAll} from "../Common/methods";

export default {
  name: "TrackingTable",
  components: {
    Tabs,
    ExpandRankTableChart,
    MetadataKeywordsTable,
    ProgressIndicator,
    ModalComponent,
    CustomSelectNew,
    TranslateTool,
    SvgIcon,
    KeywordToolNew,
    KeywordTool,
    InAsoTool,
    TranslateOneTool,
    CompetitorsInTopTool,
    ResultsTool,
    RankChartTool,
    AppRankTool,
    ImpressionsTool,
    SapTool,
    SimpleInfoTable,
    DifficultyTool
  },
  props: {
    currentApp: {
      type: Object,
      required: true,
    },
    currentCountry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,

      keywordsGroups: new KeywordsGroups(),

      tabSelectOpen: false,

      items: [],
      allCountriesMode: false,

      groupsSelectReloadKey: 0,
      groupsInfo: {},
      selectedGroup: null,
      selectedTab: null,

      dates: {
        start: getNDaysAgoDate(1),
        end: getNDaysAgoDate(0),
      },

      lastOpenedItem: null,
      searchInputValue: '',
    }
  },
  watch: {
    allCountriesMode() {
      this.fetchData();
    },
    selectedTab() {
      nextTick(() => {
        this.$refs.table.resetPagination();
      });
    },
  },
  emits: ['tracking-keywords-changed'],
  computed: {
    downloadUrl() {
      if (!this.loading) {
        const table = this.$refs.table;
        let urlQuery = table.getDataProvider().getSearchFilterData().paramsUrl();
        urlQuery += '&application_id=' + this.currentApp.id
            + '&country_code=' + this.currentCountry.code
            + '&date_from=' + formatDate(this.dates.start, 'digits-dash')
            + '&date_to=' + formatDate(this.dates.end, 'digits-dash');
        return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_TRACKING + '?p=1' + urlQuery;
      }

      return null
    },
    columns() {
      return {
        'keywords': {
          header: 'Keywords',
          style: {width: '100%', minWidth: '225px', paddingLeft: 0, left: '65px'},
          headerStyle: {paddingLeft: 0, left: '65px'},
          orientation: 'left',
          sortBy: this.checkedItems.length ? null : 'keyword',
          sortExportParam: 'keyword'
        },
        'popularity': {
          header: 'Popularity',
          style: {paddingRight: '0px'},
          headerStyle: {minWidth: '130px', paddingRight: '0px'},
          orientation: 'right',
          sortBy: 'popularity',
          sortExportParam: 'popularity'
        },
        'impression': {
          header: 'Daily Impr',
          style: {},
          headerStyle: {minWidth: '125px'},
          orientation: 'right',
          sortBy: 'impression',
          sortExportParam: 'impression'
        },
        'difficulty': {
          header: 'Difficulty',
          style: {},
          headerStyle: {minWidth: '120px'},
          orientation: 'right',
          sortBy: 'difficulty',
          sortExportParam: 'difficulty'
        },
        'first_rank': {
          header: 'App Rank',
          style: {paddingRight: '0px'},
          headerStyle: {minWidth: '110px', paddingRight: '0px'},
          orientation: 'right',
          sortBy: (item) => {
            if (item.first_rank_info.rank === '-' || item.first_rank_info.rank === 'N/A') {
              return -8888;
            } else {
              return parseInt(item.first_rank_info.rank) * -1;
            }
          },
          sortExportParam: 'first_rank'
        },
        'last_rank': {
          header: 'App Rank',
          style: {paddingRight: '0px'},
          headerStyle: {minWidth: '110px', paddingRight: '0px'},
          orientation: 'right',
          sortBy: (item) => {
            if (item.last_rank_info.difference === 'out') {
              return -9999;
            } else if (item.last_rank_info.rank === '-' || item.last_rank_info.rank === 'N/A') {
              return -8888;
            } else {
              return parseInt(item.last_rank_info.rank) * -1;
            }
          },
          sortExportParam: 'latest_rank'
        },
        'rank_history': {
          header: 'Rank Chart',
          style: {},
          headerStyle: {minWidth: '130px'},
          orientation: 'right',
          sortBy: null,
        },
        'applications_count': {
          header: 'Results',
          style: {},
          headerStyle: {minWidth: '100px'},
          orientation: 'right',
          sortBy: (item) => {
            return item.applications_count ? parseInt(item.applications_count) : null;
          },
          sortExportParam: 'applications_count'
        },
        'top_competitors': {
          header: 'Comp. in Top 10',
          style: {},
          headerStyle: {minWidth: '120px', textAlign: 'right'},
          orientation: 'right',
          sortBy: (item) => {
            return item.top_competitors.competitors_count ?? 0;
          },
          sortExportParam: 'top_competitors'
        },
      };
    },
    filtersConfigs() {
      return [
        popularityFilter(this),
        rankFilter(this),
        estDailyImprFilter(this),
        difficultyFilter(this),
        compInTop10Filter(this),
        nonCompInTop10Filter(this),
        wordsCountFilter(this),
        rankTrendFilter(this),
        languageFilter(this),
      ];
    },
    datePickerConfig() {
      return {
        dateFilter: this.dates,
        minDate: getDefaultMinDate(),
        maxDate: new Date(),
        resetDatePicker: () => {
          this.fetchData();
        },
        applyDatePicker: (v) => {
          this.dates = v;
          this.fetchData();
        },
      };
    },
    groupsConfig,
    checkedItems,
    allTranslated
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    arraySum,
    formatDate,
    saveFolder(f) {
      this.keywordsGroups.create(this.currentApp.id, f.name, f.description);
      if (!this.groupsInfo.by_folders) {
        this.groupsInfo.by_folders = {};
      }
      this.groupsInfo.by_folders[f.name] = 0;
    },
    startAddGroup() {
      openGlobalModal(AddFolderModal, {
        app: this.currentApp,
        handleSave: (v) => {
          this.saveFolder(v);
          RefsStore.getRef('globalModal').close();
        },
        handleCancel: () => {
          RefsStore.getRef('globalModal').close();
        }
      });
    },
    startAddGroupKeywords() {
      openGlobalModal(AddKeywordsToFolderModal, {
        app: this.currentApp,
        countryCode: this.currentCountry.code,
        keywords: this.checkedItems.map((item) => {
          return item.keyword;
        }),

        handleSave: () => {
          RefsStore.getRef('globalModal').close();
        },
        handleSaveFolder: (v) => {
          this.saveFolder(v);
        },
        handleCancel: () => {
          RefsStore.getRef('globalModal').close();
        }
      });
    },
    copyKeywords() {
      copyKeywords(this, (i) => {return i.keyword});
    },
    deleteKeywords() {
      let hasMultipleCountries = {};
      let ids = [];
      this.checkedItems.forEach((i) => {
        i.other_countries.forEach((c) => {
          if (!hasMultipleCountries[c]) {
            hasMultipleCountries[c] = [];
          }

          hasMultipleCountries[c].push(i.saved_keyword_id);
        });

        ids.push(i.saved_keyword_id);
      });

      const idsMap = {};
      for (let id of ids) {
        idsMap[id] = true;
      }
      this.items = this.items.filter((item) => {
        return !idsMap[item.saved_keyword_id];
      });

      nextTick(() => {
        this.$refs.table.resetPagination();
        keywordsTracking.deleteKeywords(
            this.currentApp.id,
            ids,
            this.currentCountry.code
        );

        if (this.allCountriesMode && Object.keys(hasMultipleCountries).length) {
          for (let country of Object.keys(hasMultipleCountries)) {
            keywordsTracking.deleteKeywords(
                this.currentApp.id,
                hasMultipleCountries[country],
                country
            );
          }
        }
      });

      this.$emit('tracking-keywords-changed');
    },
    async translateAll() {
      await translateAll(this);
    },
    async fetchData() {
      this.loading = true;

      this.selectedGroup = this.groupsConfig.list[0];
      this.selectedTab = null;

      this.items = [];

      let urlQuery = '?application_id=' + this.currentApp.id
          + '&country_code=' + this.currentCountry.code;

      urlQuery += '&date_from=' + formatDate(this.dates.start, 'digits-dash');
      urlQuery += '&date_to=' + formatDate(this.dates.end, 'digits-dash');
      if (this.allCountriesMode) {
        urlQuery += '&all_countries_keywords=true';
      }

      const data = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.TRACKING_KEYWORDS + urlQuery);

      this.items = data.list;
      delete data.groups_counts.by_folders; //TODO
      this.groupsInfo = data.groups_counts;
      this.selectedGroup = this.groupsConfig.list[0];
      this.groupsSelectReloadKey += 1;

      await nextTick(() => {
        this.$refs.table.resetPagination();
      });

      this.loading = false;
    },
    handleChartClick(item) {
      if (this.lastOpenedItem) {
        this.lastOpenedItem.opened = false;
      }

      if (this.lastOpenedItem === item) {
        this.lastOpenedItem = null;
        return;
      }

      item.opened = !item.opened;
      this.lastOpenedItem = item;
    },

    liveSearchTriggerHandler(e, flow = 'live') {
      openGlobalModal(LiveSearchGlobalModalAdapter, {
        'country': this.currentCountry,
        'flow': flow,
        'keyword': e.keyword
      })
    },
    sapChartTriggerHandler(e) {
      openGlobalModal(SapChartGlobalModalAdapter, {
        'country': this.currentCountry,
        'keyword': e.keyword
      })
    },
    autoSuggestTriggerHandler(e) {
      openGlobalModal(AutosuggestsGlobalModalAdapter, {
        'country': this.currentCountry,
        'keyword': e.keyword,
        'app': this.currentApp,
        'keywordsChangedCallback': () => {
          this.$emit('tracking-keywords-changed');
          this.fetchData();
        },
        'saveKeywordsCallback': () => {
          this.$emit('tracking-keywords-changed');
          this.fetchData();
        }
      })
    },
  }
}
</script>

<template>
  <div v-show="loading">
    <progress-indicator>Loading Keywords...</progress-indicator>
  </div>
  <div class="common-keywords-table tracking-keywords-table" v-show="!loading">
    <simple-info-table
        ref="table"
        :full-width-section="true"
        :search-by="['keyword']"
        :items="items"
        :columns="columns"
        :download-url="downloadUrl"
        :filter-configs="filtersConfigs"
        :date-picker-config="datePickerConfig"
        default-sort-index="popularity"
        :default-sort-direction="'desc'"
        :multiselect="true"
        :sticky-header="true"
        :sticky-columns="[-1, 'keywords']"
        :table-wrap-styles="{maxHeight: 'calc(100vh - 316px)', overflowY: 'scroll'}"
        :table-wrap-classes="['common-scrollbar', 'scrollWidth-10', 'color-600', 'border-radius-8', 'background-color-400']"
        :rows-per-page-list="[50, 100]"
        @search-input-changed="(v) => {searchInputValue = v}"
    >
      <template v-slot:headers="slotProps">
        <div v-if="'first_rank' === slotProps.index">
          {{ slotProps.header }}
          <br>
          <span class="extended-date">{{ formatDate(this.dates.start, 'month-day') }}</span>
        </div>
        <div v-else-if="'last_rank' === slotProps.index">
          {{ slotProps.header }}
          <br>
          <span class="extended-date">{{ formatDate(this.dates.end, 'month-day') }}</span>
        </div>
        <div v-else-if="'keywords' === slotProps.index && checkedItems.length">
          {{ checkedItems.length }}/<span style="font-weight: 400">{{ $refs.table.provider.getPagination().total }}</span>
        </div>
        <div v-else>
          {{ slotProps.header }}
        </div>
      </template>
      <template v-slot:headersAfter="slotProps">
        <div class="display-flex" v-if="slotProps.index === 'keywords'">
          <template v-if="checkedItems.length">
            <div class="font-20 ml-15 cursor-pointer hover-color-primary color-700" @click="copyKeywords" v-tooltip="{ text: 'Copy', position: 'bottom', onClickText: 'Copied' }">
              <svg-icon icon="copy"></svg-icon>
            </div>
            <div class="font-20 ml-15 cursor-pointer hover-color-primary color-700" @click="deleteKeywords" v-tooltip="{ text: 'Delete', position: 'bottom', onClickText: 'Deleted' }">
              <svg-icon icon="x-alt"></svg-icon>
            </div>
<!--            TODO-->
<!--            <div class="font-20 ml-15 cursor-pointer hover-color-primary" @click="startAddGroupKeywords" v-tooltip="{ text: 'Add keywords to the folder', position: 'bottom', classes: ['no-wrap-text'] }">-->
<!--              <svg-icon icon="add-folder"></svg-icon>-->
<!--            </div>-->
          </template>
          <template v-else>
            <div>
              <translate-tool :active="allTranslated" @translate-clicked="translateAll"/>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:endOfFiltersBlock>
        <div class="custom-select-new" v-if="groupsConfig.list.length > 0">
          <custom-select-new
              :key="groupsSelectReloadKey"
              :options="groupsConfig.list"
              :pre-selected="groupsConfig.list[0]"
              value-field="id"
              label-field="name"
              icon-field="icon"
              :styles="{
                  label: {
                    width: '220px'
                  }
                }"
              @changed="groupsConfig.onChange"
          ></custom-select-new>
        </div>
<!--        <div class="ml-15 display-flex" v-tooltip="{ text: 'Keywords from all countries', position: 'bottom', classes: ['no-wrap-text'] }">-->
<!--          <input type="checkbox"-->
<!--                 class="common-checkbox"-->
<!--                 id="all_countries_mode"-->
<!--                 v-model="allCountriesMode"-->
<!--          />-->
<!--          <label for="all_countries_mode" class="cursor-pointer">WW mode</label>-->
<!--        </div>-->
      </template>
      <template v-slot:beforeTableBlock>
        <Tabs :groups-info="groupsInfo" :selected-group="selectedGroup" :items="items" @select-tab="(index) => selectedTab = index"></Tabs>
      </template>
      <template v-slot:full-width-sections="slotProps">
        <transition name="height-collapse">
          <div v-if="slotProps.item.opened" style="overflow: hidden">
            <expand-rank-table-chart
                @close="handleChartClick(slotProps.item)"
                :app="currentApp"
                :country="currentCountry"
                :keyword="slotProps.item.keyword"
            />
          </div>
        </transition>
      </template>

      <template v-slot:items-keywords="slotProps">
        <keyword-tool-new
            :value="slotProps.item.keyword"
            :translated-value="slotProps.item.translate"
            :search-input-value="searchInputValue"
        >
          <template v-slot:hoverSection>
            <div class="display-flex actions-block">
              <div class="mr-12">
                <translate-one-tool :set-result-to="slotProps.item" :to-translate-string="slotProps.item.keyword"></translate-one-tool>
              </div>
              <div class="tool-item" v-tooltip="{ text: 'Live search', position: 'left', classes: ['no-wrap-text'] }">
                <svg-icon icon="livesearch-icon" class="live-search-icon cursor-pointer hover-color-secondary-yellow" @click="liveSearchTriggerHandler(slotProps.item)"/>
              </div>
              <div class="tool-item" v-tooltip="{ text: 'Autosuggest', position: 'left' }">
                <svg-icon icon="autosuggest-icon" class="autosuggest-icon cursor-pointer" @click="autoSuggestTriggerHandler(slotProps.item)"/>
              </div>
            </div>
          </template>
        </keyword-tool-new>
      </template>
      <template v-slot:items-popularity="slotProps">
        <div class="display-flex">
          <sap-tool :keyword="slotProps.item.keyword"
                    :sap="slotProps.item.popularity"
                    :store-key="currentApp.store.key"
                    :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
                    @sap-modal-trigger-clicked="sapChartTriggerHandler(slotProps.item)"/>
        </div>
      </template>
      <template v-slot:items-impression="slotProps">
        <div>
          <impressions-tool :value="slotProps.item.impression"/>
        </div>
      </template>
      <template v-slot:items-difficulty="slotProps">
        <div class="display-flex">
          <difficulty-tool :value="slotProps.item.difficulty"/>
        </div>
      </template>
      <template v-slot:items-first_rank="slotProps">
        <div>
          <app-rank-tool :item="slotProps.item.first_rank_info"/>
        </div>
      </template>
      <template v-slot:items-last_rank="slotProps">
        <div>
          <app-rank-tool :item="slotProps.item.last_rank_info"/>
        </div>
      </template>
      <template v-slot:items-rank_history="slotProps">
        <div>
          <rank-chart-tool :key="slotProps.item.keyword"
                           :chart-data="slotProps.item.rank_history"
                           :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
                           @chart-icon-clicked="handleChartClick(slotProps.item)"/>
        </div>
      </template>
      <template v-slot:items-applications_count="slotProps">
        <div>
          <results-tool :value="slotProps.item.applications_count"/>
        </div>
      </template>
      <template v-slot:items-top_competitors="slotProps">
        <div>
          <competitors-in-top-tool
              :key="`${currentApp.id}|${currentCountry.code}|${slotProps.item.keyword}|comp_top_tool|${slotProps.item.top_competitors.competitors_count}|${slotProps.item.top_competitors.non_competitors_count}`"
              :total="slotProps.item.top_competitors.found_top_count ?? 0"
              :competitors="slotProps.item.top_competitors.competitors_count ?? 0"
              :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
              :non-competitots="slotProps.item.top_competitors.non_competitors_count ?? 0"
              :indirect-competitors="slotProps.item.top_competitors.indirect_competitors_count ?? 0"
              @clicked="liveSearchTriggerHandler(slotProps.item, 'db')"
          />
        </div>
      </template>
    </simple-info-table>
  </div>
</template>

<style scoped lang="scss">
.tool-item {
  display: block !important;
}

.tracking-keywords-table {
  margin: 0;
}

.extended-date {
  white-space: nowrap;
  color: var(--neutral-700);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.custom-select-new {
  margin-left: 16px;
}
</style>
<style lang="scss">
.common-keywords-table.tracking-keywords-table {
  @media screen and (max-width: 767px) {
    .filters-block {
      row-gap: 16px;
      margin-bottom: 0;

      .custom-select-new {
        margin-left: 0;
      }
    }
  }

  .th_content .translate-block {
    margin-left: 0;
  }
}
</style>
