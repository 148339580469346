<template>
  <keywords-header-tabs active="tracking" @addKeyword="openKeywordsAddModal" />

  <div class="new-container"
       v-if="overheadType && overheadType !== 'appsOverhead'">
    <user-limits-exceeded>
      You have exceeded the limits of keywords and competitors for your account. Please, delete some keywords and
      competitors to fit within the current account limits.
    </user-limits-exceeded>
  </div>

  <div class="new-container">
    <div class="keywords-tracking-page">
      <tracking-table
          :key="currentApp.id + currentCountry.code"

          ref="trackingTable"
          :current-app="currentApp"
          :current-country="currentCountry"

          @tracking-keywords-changed="onTrackingChanged"
      ></tracking-table>

    </div>
  </div>

  <custom-modal :opened="showAddKeywordsModal"
                :max-width="728"
                class-name="add-keywords-modal"
                @closeModal="closeModal('showAddKeywordsModal')">
    <add-keywords-modal :current-country-code="currentCountry?.code"
                        :current-country-name="currentCountry?.name"
                        :current-app="currentApp"
                        :tracked-keywords="keywordsList"
                        @save-keywords="saveAddedKeywords"/>

  </custom-modal>
</template>

<script>
import {mapGetters} from "vuex";
import TheHeader from '@/components/TheHeader/index';
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';
import CustomTabs from "@/views/SearchTerms/KeywordsTracking/components/CustomTabs";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import UserLimitsExceeded from "@/components/UI/UserLimitsExceeded/index.vue";
import FiltersComponent from "@/components/UI/FiltersComponent/index.vue";
import {getCookie} from "@/utils/utils";
import AddKeywordsModal from "@/components/Modals/AddKeywordsModal/index.vue";
import TableWrapComponent from "@/views/SearchTerms/KeywordsTracking/components/TableWrapComponent/index.vue";
import NoDataFoundBlock from "@/components/DataTables/NoDataFoundBlock/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import DatepickerWithRange from "@/components/UI/DatepickerWithRange/index.vue";
import TrackingTable from "./TrackingTable.vue";
import OrderRADASOModal from "@/components/OrderRadaso/Modal/index.vue";
import OrderRadasoModal from "@/components/OrderRadaso/Modal/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import KeywordsHeaderTabs from "@/views/SearchTerms/components/KeywordsHeaderTabs/index.vue";

export default {
  name: 'KeywordsTracking',
  components: {
    KeywordsHeaderTabs,
    SvgIcon,
    OrderRadasoButton,
    OrderRadasoModal,
    OrderRADASOModal,
    TrackingTable,
    'the-header': TheHeader,
    'basic-search-input': BasicSearchInput,
    'custom-tabs': CustomTabs,
    'preloader-table-small': PreloaderTableSmall,
    'custom-dropdown': CustomDropdown,
    'data-table-checkbox': TableCheckbox,
    'date-picker-with-range': DatepickerWithRange,
    UserLimitsExceeded,
    FiltersComponent,
    TableWrapComponent,
    AddKeywordsModal,
    NoDataFoundBlock,
    TranslateTool,
  },
  data() {
    return {
      title: '',
      loaderText: '',
      currentCountryCode: null,
      showAddKeywordsModal: false,
      keywordsLoaded: false,
      keywordsList: [],
    }
  },
  methods: {
    async saveAddedKeywords(keywordsArray) {
      const currentAppId = getCookie('currentAppId');
      if (currentAppId && keywordsArray?.length > 0) {
        this.$refs.trackingTable.loading = true;

        this.closeModal('showAddKeywordsModal');

        await this.saveAddedKeywordsFunc(keywordsArray, currentAppId, this.currentCountry?.code);
        await this.$refs.trackingTable.fetchData();

        this.onTrackingChanged();
      }
    },
    async saveAutosuggests(keywordsArray) {
      await this.saveAddedKeywords(keywordsArray);
    },
    showInitialModal() {
      if (JSON.stringify(this.keywordsList) === '[]' || JSON.stringify(this.keywordsList) === '{}') {
        this.showAddKeywordsModal = true;
      }
    },
    openKeywordsAddModal() {
      this.openModal('showAddKeywordsModal');
    },
    setDocumentTitle() {
      let title = 'Tracking Keywords';
      if (this.currentCountry?.name) {
        title += ' ' + this.currentCountry.name;
      }
      this.setPageTitle(title);
    },
    async onTrackingChanged() {
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      await this.fetchDataCounts();
    },
  },
  async created() {
    this.keywordsLoaded = false;

    if (getCookie('currentAppId')) {
      this.setDocumentTitle();

      this.currentCountryCode = this.currentCountry?.code;
      await this.fetchCountryCatalog();
      await this.fetchDataCounts();
    }
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'promiseSettledData',
      'dataCounts',
      'dataCountsLoaded',
      'userSubscription',
      'userLimits',
    ]),
    overheadType() {
      if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_apps?.used > this.userLimits?.max_apps?.total)) {
        return 'appsOverhead';
      } else if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_keywords?.used > this.userLimits?.max_keywords?.total)) {
        return 'keywordsOverhead';
      } else if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_competitors?.used > this.userLimits?.max_competitors?.total)) {
        return 'competitorsOverhead';
      } else {
        return false;
      }
    },
  },
  watch: {

  },
}
</script>

<!--TODO обязательно сделать scoped! все что общее вынести в .common-keywords-table-->
<style lang="scss" src="./styles.scss"></style>