<template>
  <div class="tracking-keywords-table"
       :class="constructTableClasses()">
    <adaptive-data-table :headers="headers"
                         :items="localTableItems"
                         :pagination="tablePagination"
                         :sorting-meta="sortData"
                         :items-per-page="+tablePagination.per_page"
                         :rows-per-page-items="[50, 100, 250, 500, 1000]"
                         :sorting-keys="{direction: 'order', sorting: 'sort', paginate: 'per_page'}"
                         :no-pagination="false"
                         :show-per-page-selector="true"
                         :table-classes="'words-custom-table'"
                         @check-all-clicked="checkAllClicked"
                         :check-all-changed="checkAllChanged"
                         :items-checked="keywordsTrackingCheckedAmount"
                         :items-amount="+tablePagination?.total"
                         :scroll-after-click="true"
                         @update-sort="updateSort">

      <template v-slot:extended-header-text
                v-if="searchInputValue !== ''">
        <span class="extended-text">with “{{ searchInputValue }}”</span>
      </template>

      <template v-slot:extended-ranking-from>
        <span class="extended-date">{{formatDateFunc(rankFrom, 'month-day-year')}}</span>
      </template>

      <template v-slot:extended-ranking-to>
        <span class="extended-date">{{formatDateFunc(rankTo, 'month-day-year')}}</span>
      </template>

      <template v-slot:select-all
                v-if="keywordsTrackingCheckedAmount >= 1 && keywordsTrackingCheckedAmount >= tablePagination?.per_page">
        <div class="tr select-all-row">
          <div class="th select-all-inner-block">
            <template v-if="tableFullItems?.length === 0 || (tableFullItems?.length !== tablePagination?.total)">
              <div class="inner-content">
                <div class="select-all"
                     @click="selectAllKeywords">Select All Pages
                </div>
              </div>
            </template>
            <template v-else>
              <div class="inner-content">
                <div class="select-all"
                     @click="unSelectAllKeywords">Unselect All Pages
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <template v-slot:multi-actions>
        <div class="table-head-options-block">
          <div class="selected-count"
               v-if="keywordsTrackingCheckedAmount > 0">

            <template v-if="tableFullItems?.length > 0">
              {{ tableFullItems.length }}/{{ tablePagination?.total }}
            </template>
            <template v-else>
              {{ keywordsTrackingCheckedAmount }}/{{ tablePagination?.total }}
            </template>
          </div>

          <table-actions-tooltip @click="multiCopy">
            <template v-slot:tooltip-icon>
              <svg-icon icon="copy"
                        class="action-icon action-copy-icon"/>
            </template>
            <template v-slot:tooltip-text>
              <template v-if="showCopyActiveFlag">
                Copied!
              </template>
              <template v-else>
                Copy
              </template>
            </template>
          </table-actions-tooltip>

          <table-actions-tooltip @click="keywordsMultiDelete">
            <template v-slot:tooltip-icon>
              <svg-icon icon="close-alt"
                        class="action-icon action-delete-icon"/>
            </template>
            <template v-slot:tooltip-text>
              <template v-if="showDeclinedActiveFlag">
                Deleted!
              </template>
              <template v-else>
                Delete
              </template>
            </template>
          </table-actions-tooltip>

        </div>
      </template>

      <template v-slot:items="{item}">
        <div class="td keywords-column">
          <div class="inner-content pos-relative">
            <keyword-tool :key="componentKey + item?.keyword + item?.translate"
                          :value="item?.keyword"
                          :translated-value="item?.translate"
                          :search-input-value="searchInputValue">
              <template v-slot:checkbox>
                <data-table-checkbox @input-checked="keywordsTableInputChecked($event, item)"
                                     :checked="item?.checked"/>
              </template>
              <template v-slot:actions-block>
                <div class="mr-12">
                  <translate-one-tool :set-result-to="item" :to-translate-string="item.keyword"></translate-one-tool>
                </div>

                <custom-tooltip :classes="'tool-item'"
                                direction-horizontal="center"
                                @click="liveSearchTriggerHandler(item)">
                  <template v-slot:slotTrigger>
                    <svg-icon icon="livesearch-icon"
                              class="live-search-icon"/>
                  </template>
                  <template v-slot:slotContent>
                    Live Search
                  </template>
                </custom-tooltip>

                <custom-tooltip :classes="'tool-item'"
                                :style="{minWidth: '100px'}"
                                @click="autoSuggestTriggerHandler(item)">
                  <template v-slot:slotTrigger>
                    <svg-icon icon="autosuggest-icon"
                              class="autosuggest-icon"/>
                  </template>
                  <template v-slot:slotContent>
                    Autosuggest
                  </template>
                </custom-tooltip>

                <in-aso-tool :current-app="currentApp"
                             :item="item"/>
              </template>
            </keyword-tool>
          </div>
        </div>

        <div class="td sap-column right"
             aria-name="popularity">
          <div class="inner-content">
            <sap-tool :keyword="item?.keyword"
                      :sap="item?.popularity"
                      :store-key="currentApp?.store?.key"
                      @sap-modal-trigger-clicked="sapModalTriggerHandler"/>
          </div>
        </div>

        <div class="td impressions-column right"
             aria-name="impression"
             v-if="currentApp?.store?.key === 'APP_STORE'">
          <div class="inner-content">
            <impressions-tool :value="item?.impression"/>
          </div>
        </div>

        <div class="td difficulty-column right"
             aria-name="difficulty">
          <div class="inner-content">
            <difficulty-tool :value="item?.difficulty"/>
          </div>
        </div>

        <div class="td rank-column right"
             aria-name="latest_rank">
          <div class="inner-content">
            <app-rank-tool :item="item?.last_rank_info"/>
          </div>
        </div>

        <div class="td rank-column right"
             aria-name="latest_rank_from">
          <div class="inner-content">
            <app-rank-tool :item="item?.first_rank_info"/>
          </div>
        </div>

        <div class="td rank-column right"
             aria-name="latest_rank_to">
          <div class="inner-content">
            <app-rank-tool :item="item?.last_rank_info"/>
          </div>
        </div>

        <div class="td chart-column right"
             aria-name="rank_chart">
          <div class="inner-content h100p">
            <rank-chart-tool :chart-data="item?.rank_history"
                             @chart-icon-clicked="openRanks(item)"
            />
          </div>
        </div>

        <div class="td results-column right"
             aria-name="applications_count">
          <div class="inner-content">
            <results-tool :value="item?.applications_count"/>
          </div>
        </div>

        <div class="td comp-top-column right"
             aria-name="competitors_top">
          <div class="inner-content">
            <competitors-in-top-tool
                :keyword="`${currentApp.id}|${currentCountry.code}|${item.keyword}|comp_top_tool|${item.top_competitors?.competitors_count}|${item.top_competitors?.non_competitors_count}`"
                :total="item.top_competitors?.found_top_count ?? 0"
                :competitors="item.top_competitors?.competitors_count ?? 0"
                :indirect-competitors="item.top_competitors?.indirect_competitors_count ?? 0"
                :non-competitots="item.top_competitors?.non_competitors_count ?? 0"
                @clicked="liveSearchTriggerHandler({keyword: item.keyword, flow: 'db'})"
            />
          </div>
        </div>

        <div class="td action-col">
          <div class="inner-content">

            <custom-tooltip :classes="'delete-icon'"
                            width="60px"
                            @click="deleteKeyword(item)">
              <template v-slot:slotTrigger>
                <svg-icon icon="close-alt"
                          class="delete-icon"/>
              </template>
              <template v-slot:slotContent>
                Delete
              </template>
            </custom-tooltip>
          </div>
        </div>
      </template>

      <template v-slot:under_row="{item}">
        <keep-alive :key="currentApp.id + currentCountry.code + item.keyword">
          <expand-rank-table-chart
              v-if="item.is_open_ranks"

              @close="openRanks(item)"
              :app="currentApp"
              :country="currentCountry"
              :keyword="item.keyword"
          />
        </keep-alive>
      </template>

    </adaptive-data-table>

    <custom-modal :opened="showLiveSearchModal"
                  :max-width="580"
                  :immutable-width="true"
                  class-name="live-search-modal"
                  @closeModal="closeModal('showLiveSearchModal')">
      <live-search-modal :current-country-code="currentCountry?.code"
                         :current-country-name="currentCountry?.name"
                         :current-country-id="currentCountry?.id"
                         :flow="liveSearchFlow"
                         @search-competitor-changed="liveSearchCompetitorChanged"
                         :current-keyword-item="currentKeywordItem"/>
    </custom-modal>

    <custom-modal :opened="showTableChartModal"
                  :max-width="728"
                  class-name="popularity-chart-modal"
                  @closeModal="closeModal('showTableChartModal')">
      <sap-chart-modal :current-country-code="currentCountry?.code"
                       :current-country-name="currentCountry?.name"
                       :current-keyword-item="currentKeywordItem"/>
    </custom-modal>

    <custom-modal :opened="showAutoSuggestModal"
                  class-name="add-autosuggest-modal"
                  @closeModal="closeModal('showAutoSuggestModal')">
      <autosuggests-modal :current-country-code="currentCountry?.code"
                          :current-country-name="currentCountry?.name"
                          :current-keyword-item="currentKeywordItem"
                          :current-app="currentApp"
                          @save-keywords="saveAutosuggests"
                          @keywords-changed="keywordsChanged"
                          @closeModal="closeModal('showAutoSuggestModal')"/>
    </custom-modal>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import AdaptiveDataTable from "@/components/DataTables/AdaptiveDataTable/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import ImpressionsTool from "@/components/DataTables/Tools/ImpressionsTool/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import RankChartTool from "@/components/DataTables/Tools/RankChartTool/index.vue";
import ResultsTool from "@/components/DataTables/Tools/ResultsTool/index.vue";
import DifficultyTool from "@/components/DataTables/Tools/DifficultyTool/index.vue";
import KeywordTool from "@/components/DataTables/Tools/KeywordTool/index.vue";
import LiveSearchModal from "@/components/Modals/LiveSearchModal/index.vue";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import AutosuggestsModal from "@/components/Modals/AutosuggestsModal/index.vue";
import InAsoTool from "@/components/DataTables/Tools/InAsoTool/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import {formatDate} from "@/utils/utils";
import ExpandRankTableChart from "@/components/DataTables/Tools/ExpandRankTableChart/ExpandRankTableChart.vue";
import CompetitorsInTopTool from "@/components/DataTables/Tools/CompetitorsInTopTool/index.vue";
import customCompetitors from "@/api/modules/custom-competitors";
import TranslateOneTool from "../../../../../components/DataTables/Tools/TranslateOneTool/index.vue";

export default defineComponent({
  name: "TrackingTableWrapComponent",
  components: {
    TranslateOneTool,
    CompetitorsInTopTool,
    ExpandRankTableChart,
    'live-search-modal': LiveSearchModal,
    'sap-chart-modal': SapChartModal,
    'autosuggests-modal': AutosuggestsModal,
    'data-table-checkbox': TableCheckbox,
    AdaptiveDataTable,
    SapTool,
    ImpressionsTool,
    AppRankTool,
    RankChartTool,
    ResultsTool,
    DifficultyTool,
    KeywordTool,
    InAsoTool,
    TableActionsTooltip,
  },
  props: {
    headers: {
      type: Object,
    },
    tableItems: {
      type: Array,
    },
    tableFullItems: {
      type: Array,
    },
    sortData: {
      type: Object
    },
    tablePagination: {
      type: Object
    },
    currentCountry: {
      type: Object
    },
    currentApp: {
      type: Object
    },
    searchInputValue: {
      type: String,
    },
    rankFrom: {},
    rankTo: {},
  },
  emits: [
    'multi-delete-clicked',
    'single-delete-clicked',
    'update-sort',
    'select-all-clicked',
    'table-item-checked',
    'unselect-all-clicked',
    'save-autosuggests',
    'keywords-changed',
  ],
  data() {
    return {
      showCopyActiveFlag: false,
      showDeclinedActiveFlag: false,
      deleteMultipleKeywordsClicked: false,
      checkAllChanged: false,
      showLiveSearchModal: false,
      showTableChartModal: false,
      showAutoSuggestModal: false,
      currentKeywordItem: null,
      localTableItems: [],
      componentKey: 0,

      lastRankOpenedItem: null,
      liveSearchFlow: 'live',
    }
  },
  mounted() {
    this.localTableItems = [...this.tableItems];
  },
  methods: {
    async liveSearchCompetitorChanged(e) {
      let keywords = [];
      this.tableItems.forEach((item) => {
        keywords.push(item.keyword);
      });

      let responseByKeyword = await customCompetitors.getKeywordTopCompetitorsInfo(this.currentApp.id, this.currentCountry.code, keywords);
      this.tableItems.forEach((item) => {
        if (!responseByKeyword[item.keyword]) {
          return;
        }
        item.top_competitors = responseByKeyword[item.keyword].top_competitors;
      });
    },
    openRanks(item) {
      if (this.lastRankOpenedItem && item !== this.lastRankOpenedItem) {
        this.lastRankOpenedItem.is_open_ranks = false;
      }

      if (!item.is_open_ranks) {
        item.is_open_ranks = true;
        this.lastRankOpenedItem = ref(item);
      } else {
        item.is_open_ranks = false;
        this.lastRankOpenedItem = null;
      }
    },
    saveAutosuggests(keywordsArray, mode) {
      this.$emit('save-autosuggests', keywordsArray);
      this.closeModal('showAutoSuggestModal');
    },
    keywordsChanged() {
      this.$emit('keywords-changed');
    },
    multiCopy() {
      let textAreaContent = '';
      let currentActionArray = this.tableFullItems?.length === 0 ? 'localTableItems' : 'tableFullItems';
      const checkedArray = this[currentActionArray]?.filter(keyword => keyword?.checked === true);

      checkedArray.forEach(item => {
        textAreaContent += `${item?.keyword}\n`;
      });

      const tempText = document.createElement("textarea");
      tempText.value = textAreaContent;
      document.body.appendChild(tempText);
      tempText.select();

      document.execCommand("copy");
      document.body.removeChild(tempText);

      this.showCopyActiveFlag = true;

      setTimeout(() => {
        this.showCopyActiveFlag = false;
      }, 3000);
    },
    keywordsMultiDelete() {
      const checkedArray = this.localTableItems.filter(keyword => keyword?.checked === true);
      this.$emit('multi-delete-clicked', checkedArray);
    },
    deleteKeyword(item) {
      this.$emit('single-delete-clicked', item);
    },
    checkAllClicked(allChecked) {
      let checkedArray = this.localTableItems.map(keyword => {
        return {
          ...keyword,
          checked: allChecked
        }
      });

      this.localTableItems = [...checkedArray];
      this.checkAllChanged = allChecked;
      this.$emit('unselect-all-clicked');
    },
    updateSort(data) {
      this.$emit('update-sort', data);
    },
    selectAllKeywords() {
      this.$emit('select-all-clicked');
    },
    unSelectAllKeywords() {
      this.$emit('unselect-all-clicked');
      let checkedArray = this.localTableItems.map(keyword => {
        return {
          ...keyword,
          checked: false
        }
      });

      this.checkAllChanged = false;
      this.localTableItems = [...checkedArray];
    },
    sapModalTriggerHandler(e) {
      this.currentKeywordItem = e;
      this.openModal('showTableChartModal');
    },
    liveSearchTriggerHandler(e) {
      this.currentKeywordItem = e?.keyword;
      this.liveSearchFlow = e.flow ?? 'live';
      this.openModal('showLiveSearchModal');
    },
    autoSuggestTriggerHandler(e) {
      this.currentKeywordItem = e?.keyword;
      this.openModal('showAutoSuggestModal');
    },
    keywordsTableInputChecked(checked, item) {
      this.showCopyActiveFlag = false;

      const checkedArray = this.localTableItems.map(keyword => {
        return {
          ...keyword,
          checked: keyword.keyword === item.keyword ? checked : keyword.checked,
        };
      });
      this.localTableItems = [...checkedArray];

      if (!checked) {
        this.checkAllChanged = checked;
      }

      this.$emit('table-item-checked');
    },
    constructTableClasses() {
      let colsNamesArray = [];
      for (const key in this.headers) {
        if (this.headers[key]?.isActive) {
          colsNamesArray.push(this.headers[key]?.value);
        }
      }
      return colsNamesArray.join(' ');
    },
    formatDateFunc(date, type) {
      return formatDate(date, type);
    }
  },
  computed: {
    keywordsTrackingCheckedAmount() {
      const checkedArray = this.localTableItems.filter(keyword => keyword?.checked === true);
      return checkedArray?.length;
    },
  },
  watch: {
    tableItems(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.localTableItems = [...newVal];
        this.componentKey++;
      }
    },
  }
})
</script>

<style lang="scss" src="./styles.scss"></style>